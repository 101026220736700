<template>
  <page-layout linkDefault1="/" title="Sessions" :backButton="true">
    <Loading v-if="loading" />
    <div v-else>
      <Calendar />
      <div class="flex flex-row items-center justify-start mr-5 ml-8 mt-2" v-if="requests?.length > 0">
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">
          Requests
        </p>
      </div>

      <div class="flex flex-col justify-center items-center mt-2 px-4">
        <session-info v-for="(_session, i) in requests" :key="i" :session="_session" />
      </div>
      <div class="flex flex-row items-center justify-start mr-5 ml-8" v-if="upCommingSessions?.length > 0">
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">
          Upcoming Sessions
        </p>
      </div>

      <div class="flex flex-col justify-center items-center mt-2 px-4">
        <session-info v-for="(_session, i) in upCommingSessions" :key="i" :session="_session" />
      </div>

      <div class="flex flex-row items-center justify-start mr-5 ml-8 mt-4" v-if="pastSessions?.length > 0">
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">
          Past Sessions
        </p>
      </div>

      <div class="flex flex-col justify-center items-center mt-2 px-4">
        <session-info class="mb-3" v-for="(_session, i) in pastSessions" :key="i" :session="_session" />
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '../../components/base/pageLayout.vue';
import SessionInfo from "../../components/SessionInfo.vue";
import Loading from "../../components/Loading.vue";
import Calendar from '../../components/buttons/Calendar.vue';
export default {
  components: { PageLayout, SessionInfo, Loading, Calendar },
  data() {
    return {
      user: null,
      allSessions: [],
      pastSessions: [],
      upCommingSessions: [],
      loading: true,
    };
  },
  created() {
    this.setUser();
    this.getSession();
  },
  methods: {
    setUser() { this.user = this.$store.state.user; },
    getSession() {
      this.allSessions = this.user.sessions;
      if (this.$store.state.user_tutor) this.requests = this.$store.state.user_tutor.unconfirmed_sessions?.filter(s => !s.is_completed);
      for (var i = 0; i < this.allSessions.length; i++) {
        if (this.allSessions[i].is_completed === true) {
          this.pastSessions.push(this.allSessions[i]);
        } else {
          this.upCommingSessions.push(this.allSessions[i]);
        }
      }
      this.loading = false;
    },
  },
}
</script>
